import {TemplateResult, html, render} from 'lit-html'
import {
  activate as activateNavigation,
  deactivate as deactivateNavigation,
  focus as focusNavigation
} from './navigation'
import {fire} from 'delegated-events'
import memoize from '@github/memoize/dist/decorator'
import {requestSubmit} from './form'
import {target} from '@github/catalyst'
import {until} from 'lit-html/directives/until'

export interface Suggestion {
  value: string
  description?: string
  isAlpha?: boolean
  isBeta?: boolean
}

// Export the cache to allow it be be cleared in tests
export const memoizeCache = new Map()

/**
 * When using any code example from this comment block, replace
 * `your-component` with your component's HTML tag name.
 *
 * Catalyst components that extend this base class need to have:
 * - An element with data-target="your-component.autocompleteDropdown"
 * - An element within that with data-target="your-component.autocompleteResults"
 * - (Optional) An element with data-target="your-component.clearButton"
 *   This in turns needs data-action="click:your-component#clear"
 * - A form with data-target="your-component.searchForm"
 *   This in turns needs the following data-action values:
 *    navigation:keydown:your-component#handleFormKeydownEvent
 *    navigation:open:your-component#handleSelectedSuggestionResultEvent
 * - An input field with data-target="your-component.searchInput"
 *   This in turn needs the following data attributes:
 *   - data-initial-value - the query value at the time of page-load
 *   - data-default-value - the default query, put into the search box when
 *     the search is cleared. For most filters, this will be an empty string,
 *     but some filters may enforce a default. For example, that there must
 *     always be an "is:" value and that this defaults to "is:open"
 *   - data-suggestable-qualifiers; optional - alternatively, override
 *     fetchQualifierSuggestions and define the qualifiers in the TypeScript
 *     directly.
 *   - data-action:
 *       input:your-component#updateFilterSuggestionResults
 *       focusin:your-component#updateFilterSuggestionResults
 *       focusout:delay:your-component#handleSearchBlur
 *       keydown:your-component#inputKey
 *   - data-filter-support-url; optional - if this is provided, a "learn more"
 *     link pointing to this URL will be added to the invalid search message
 *
 * When creating a subclass, you should also consider whether to override
 * any of the fields listed below the targets.
 */
export abstract class BaseFilterElement extends HTMLElement {
  @target autocompleteDropdown: HTMLElement
  @target autocompleteResults: HTMLElement
  @target clearButton: HTMLButtonElement | null
  @target searchForm: HTMLFormElement
  @target searchInput: HTMLInputElement

  showAllQualifiersIfNoneMatch = true
  fuzzyMatchQualifiers = false
  fuzzyMatchValues = true
  showSubmissionOptionIfInvalidSearchTerms = false
  suggestionsTitle = 'Available filters'
  spaceBetweenValueAndDescription = true
  selectorOfElementToActivateOnBlur: string | null = null

  @memoize({cache: memoizeCache})
  async cachedJSON<T>(url: RequestInfo): Promise<T> {
    const response = await fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json'
      }
    })
    if (!response.ok) {
      const responseError = new Error()
      const statusText = response.statusText ? ` ${response.statusText}` : ''
      responseError.message = `HTTP ${response.status}${statusText}`
      throw responseError
    }
    return response.json()
  }

  // As an alternative to defining the qualifiers in the template, you can
  // override this method to return an array of Suggestions, defined in the
  // TypeScript itself.
  fetchQualifierSuggestions(): Suggestion[] {
    const attribute = 'data-suggestable-qualifiers'
    const qualifiersJSON = this.searchInput.getAttribute(attribute)
    if (qualifiersJSON === null) {
      throw new Error(`
        ${attribute} is missing from ${this.searchInput.getAttribute('data-target')}.
        Either add it or override fetchQualifierSuggestions.
      `)
    }
    return JSON.parse(qualifiersJSON)
  }

  // In the search "repo:github/github", the qualifier is "repo" (no colon)
  // This method should return a Promise that resolves to an array of Suggestions
  // that are valid for the given qualifier.
  //
  // The most common pattern here is to either:
  // 1) Retrieve a hardcoded list of suggestions from a data attribute
  // 2) Retrieve a URL from a data attribute and call this.cachedJSON
  // to retrieve the suggestions from that.
  //
  // You may want to mix and match approaches depending on the qualifier passed.
  abstract fetchSuggestionsForQualifier(qualifier: string): Promise<Suggestion[]>

  // Show the dropdown, and disable navigation
  hideFilterSuggestions() {
    deactivateNavigation(this.searchForm)
    this.autocompleteDropdown.hidden = true
    this.searchInput.setAttribute('aria-expanded', 'false')
  }

  // Parses the current search query to decide what autocompletes to show
  updateFilterSuggestionResults(): void {
    const searchString = this.searchInput.value

    // The `currentString` is the text before the cursor position up until the
    // previous whitespace, ignoring whitespace in quoted values.
    // e.g. in: `repo:foo/bar reason:me| blah` it will be "reason:me"
    const textBeforeCursor = searchString.slice(0, this.searchInput.selectionEnd!)
    const currentString = (textBeforeCursor.match(/(:"[^"]+"?|\S)+$/) || [''])[0].replace(/"/g, '')

    // Make sure results are visible
    this.autocompleteDropdown.hidden = false
    this.searchInput.setAttribute('aria-expanded', 'true')

    // Update the suggestions list
    // If the current string has a ":" then we are autocompleting the value,
    // otherwise we are still autocompleting a qualifier
    const [qualifier, value] = currentString.split(':')

    if (value != null) {
      this.renderValueSuggestions(qualifier, value)
    } else {
      this.renderQualifierSuggestions(qualifier)
    }

    if (
      searchString.trim().length > 0 &&
      (!this.invalidSearchTerms() || this.showSubmissionOptionIfInvalidSearchTerms) &&
      !this.searchMatchesDefault()
    ) {
      // If it's a non-empty, valid search that doesn't match the default, show
      // the clear button and focus on the first item in the suggestions list:
      // the "submit" option to trigger the search automatically on return
      if (this.clearButton) this.clearButton.hidden = false
      focusNavigation(this.searchForm)
    } else {
      // Otherwise, hide the clear button, and enable keyboard navigation
      // of the suggestions list, but don't highlight anything
      if (this.clearButton) this.clearButton.hidden = true
      activateNavigation(this.searchForm)
    }
  }

  // handles a suggestion item being selected
  handleSelectedSuggestionResultEvent(event: Event) {
    const selectedSuggestion = event.target as Element

    // if the user selected the "search" entry, just submit the search form
    if (selectedSuggestion.hasAttribute('data-search')) {
      requestSubmit(this.searchForm)
      return
    }

    // otherwise, replace the currently typed string with the suggested string in the input field
    let suggestedText = selectedSuggestion.getAttribute('data-value')!
    // append a space if the user did not type a qualifier:
    if (suggestedText[suggestedText.length - 1] !== ':') {
      suggestedText += ' '
    }
    const textBeforeCursor = this.searchInput.value.slice(0, this.searchInput.selectionEnd!).replace(/\S+$/, '')
    const textAfterCursor = this.searchInput.value.slice(this.searchInput.selectionEnd!)
    //add a space after the cursor if the user is typing in the middle of the field
    const spacer = textAfterCursor[0] !== ' ' ? ' ' : ''

    this.searchInput.value = textBeforeCursor + suggestedText + spacer + textAfterCursor

    event.preventDefault()

    // return focus to the input field
    this.searchInput.focus()

    // move the cursor back to just after the suggested text
    const cursorPosition = textBeforeCursor.length + suggestedText.length
    this.searchInput.setSelectionRange(cursorPosition, cursorPosition)

    // trigger an input event so other code knows the field updated
    fire(this.searchInput, 'input')
  }

  handleFormKeydownEvent(event: CustomEvent) {
    if (event.detail.hotkey === 'Enter') {
      // Only submit if the input is empty
      // If it has valid content, then the submit option will be shown
      // (and focussed) in the dropdown, so submission happens that way.
      if (this.searchInput.value.trim() !== '') {
        return
      }

      // Don't submit if only has "loading..."
      if (this.autocompleteResults.querySelector('.js-filter-loading')) {
        return
      }

      // Don't submit if we are choosing an autocomplete suggestion
      if (this.autocompleteResults.querySelector('.js-navigation-item.navigation-focus')) {
        return
      }

      requestSubmit(this.searchForm)
    }
  }

  // Clear search and refresh list of alerts when a user clicks the 'x' icon in the search bar
  clear() {
    this.searchInput.value = this.getDefaultSearch()

    if (this.getInitialValue().trim().length === 0) {
      // The query started off blank and has been cleared so it's blank again. Rather than reloading
      // the page, just clear the input and refresh the display state.
      this.updateFilterSuggestionResults()
    } else {
      // The query has been cleared, and the blank query is different from the active one: execute the
      // blank query so we refresh the list of results.
      requestSubmit(this.searchForm)
    }
  }

  // Render autocomplete suggestions to match qualifier names from the currently typed text
  renderQualifierSuggestions(filterString: string): void {
    if (this.showAllQualifiersIfNoneMatch) {
      this.renderMatchingOrAllQualifierSuggestions(filterString)
    } else {
      this.renderMatchingQualifierSuggestions(filterString)
    }
  }

  // Render autocomplete suggestions to match qualifier names from the currently typed text
  // or all qualifiers if none match.
  private renderMatchingOrAllQualifierSuggestions(filterString: string): void {
    const suggestions = this.fetchQualifierSuggestions()
    const matchingSuggestions = this.filterSuggestionsList(suggestions, filterString, {
      fuzzy: this.fuzzyMatchQualifiers
    })
      /* eslint-disable-next-line github/no-then */
      .then(matching => {
        // If there are no matching suggestions, just show them all
        if (matching.length === 0) return suggestions
        return matching
      })

    this.renderSuggestionDropdown(matchingSuggestions)
  }

  // Render autocomplete suggestions to match qualifier names from the currently typed text
  private renderMatchingQualifierSuggestions(filterString: string): void {
    const matchingSuggestions = this.filterSuggestionsList(this.fetchQualifierSuggestions(), filterString, {
      fuzzy: this.fuzzyMatchQualifiers
    })
    this.renderSuggestionDropdown(matchingSuggestions)
  }

  // Render autocomplete suggestions to match qualifier values from the currently typed text.
  //
  // qualifier - Qualifier to find value suggestions for.
  // filterString - Filter input to filter suggestions by.
  renderValueSuggestions(qualifier: string, filterString: string) {
    const matchingSuggestions = this.fetchMatchingSuggestions(qualifier, filterString)
    this.renderSuggestionDropdown(matchingSuggestions)
  }

  // Fetch value suggestions for the given qualifier.
  //
  // qualifier - Qualifier to fetch matching suggestions for.
  // filterString - Filter input to filter suggestions by.
  //
  // Returns a list of matching suggestions, prefixed with the given qualifier.
  async fetchMatchingSuggestions(qualifier: string, filterString: string): Promise<Suggestion[]> {
    const suggestionsPromise = this.fetchSuggestionsForQualifier(qualifier)
    const suggestions = await this.filterSuggestionsList(suggestionsPromise, filterString, {
      fuzzy: this.fuzzyMatchValues
    })

    // prepend the qualifier to each suggestion
    return suggestions.map(suggestion => ({
      value: `${qualifier}:${suggestion.value}`,
      description: suggestion.description
    }))
  }

  // filter a (promise of a) list of suggestions by a search query
  async filterSuggestionsList(
    suggestionsPromise: Promise<Suggestion[]> | Suggestion[],
    _search: string,
    {fuzzy} = {fuzzy: true}
  ): Promise<Suggestion[]> {
    const suggestions = await suggestionsPromise
    const search = _search.trim().toLowerCase()

    // if the search is empty, just return all results
    if (!search || search.length === 0) {
      return suggestions
    }

    return suggestions.filter(suggestion => {
      if (fuzzy) return suggestion.value.toLowerCase().includes(search)
      return suggestion.value.toLowerCase().startsWith(search)
    })
  }

  // Render a promise of suggestions into the dropdown
  // If the suggestions haven't finished loading,  "loading..." will be rendered until they do
  renderSuggestionDropdown(suggestionsPromise: Promise<Suggestion[]>): void {
    render(
      html`
        <div>
          ${this.renderSearchWarningIfRequired()}
          ${this.shouldRenderSubmissionOption() ? this.renderSearchSuggestion() : ''}
          ${until(this.renderSuggestionList(suggestionsPromise), this.renderLoadingItem())}
        </div>
      `,
      this.autocompleteResults
    )

    this.postDropdownRender()
  }

  renderSearchWarningIfRequired(): TemplateResult | string {
    const invalidTerms = this.invalidSearchTerms()
    if (!invalidTerms || invalidTerms.length === 0) return ''

    let link = html``
    const supportURL = this.getFilterSupportURL()
    if (supportURL) {
      link = html`<a class="js-navigation-item js-navigation-open px-1" href="${supportURL}" target="_blank">
        Learn more about filters.
      </a>`
    }

    return html`
      <div class="color-bg-warning color-text-secondary ml-n2 mr-n2 mt-n1 py-1 px-2 js-search-warning-container">
        Sorry, we don't support the <span class="text-bold">${invalidTerms}</span> filter yet. ${link}
      </div>
    `
  }

  // The attribute retrieved here is optional, so may not be present
  getFilterSupportURL(): string | null {
    return this.searchInput.getAttribute('data-filter-support-url')
  }

  // Subclasses may wish to perform additional rendering after the
  // main dropdown has rendered.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  postDropdownRender(): void {}

  // Renders a result (for the top of the list) that the user can select to submit the search
  renderSearchSuggestion() {
    const searchString = this.searchInput.value.trim()
    if (searchString.length === 0) {
      return html``
    }

    // don't prompt users to submit a searchString containing a qualifier with no value
    if (/:\s|:$/g.test(searchString)) return html``

    return html`
      <div class="border-bottom-0 rounded-1 py-1 px-2 mx-0 mb-1 js-navigation-item" data-search="true">
        <span class="text-bold">${searchString}</span> - submit
      </div>
    `
  }

  // Returns a string of all invalid search terms in the current query
  // For example 'invalid1 invalid2'. If no terms are invalid, return
  // null or the empty string.
  //
  // If the concept of an invalid term is not relevant, just return null.
  abstract invalidSearchTerms(): string | null

  searchMatchesDefault(): boolean {
    return this.searchInput.value.trim() === this.getDefaultSearch().trim()
  }

  // The query value at the time of page-load
  getInitialValue(): string {
    const attribute = 'data-initial-value'
    const initialVal = this.searchInput.getAttribute(attribute)
    if (initialVal === null) {
      throw new Error(`${attribute} is missing from search input`)
    }
    return initialVal
  }

  // The value that should be put into the search input when the user
  // clears the search. Often this will be the empty string, but some
  // filters enforce that certain qualifiers are present.
  getDefaultSearch(): string {
    const attribute = 'data-default-value'
    const defaultSearch = this.searchInput.getAttribute(attribute)
    if (defaultSearch === null) {
      throw new Error(`${attribute} is missing from search input`)
    }
    return defaultSearch
  }

  // Renders a title like "Narrow your search" in the dropdown
  renderSuggestionsTitle() {
    return html`<h6 class="width-full text-normal border-bottom color-bg-primary color-text-secondary py-2 mb-2">
      ${this.suggestionsTitle}
    </h6>`
  }

  // Renders a list of suggestions for the dropdown.
  async renderSuggestionList(_suggestions: Promise<Suggestion[]>) {
    const suggestions = await _suggestions

    const suggestionsHTML = suggestions.map(
      suggestion =>
        html`
          <div
            class="border-bottom-0 rounded-1 py-1 px-2 mx-0 mb-1 js-navigation-item"
            data-value="${suggestion.value}"
          >
            <span class="text-bold">${suggestion.value}</span>${this.spaceBetweenValueAndDescription ? ' ' : ''}<span
              class="autocomplete-text-qualifier color-text-tertiary"
              >&nbsp;${suggestion.description}</span
            >
            ${suggestion.isAlpha && this.alphaTag} ${suggestion.isBeta && this.betaTag}
          </div>
        `
    )

    if (suggestionsHTML.length) {
      suggestionsHTML.unshift(this.renderSuggestionsTitle())
    }

    return suggestionsHTML
  }

  renderLoadingItem() {
    return html`
      ${this.renderSuggestionsTitle()}
      <span class="js-filter-loading">loading...</span>
    `
  }

  handleSearchBlur() {
    this.hideFilterSuggestions()

    if (this.selectorOfElementToActivateOnBlur) {
      activateNavigation(document.querySelector<HTMLElement>(this.selectorOfElementToActivateOnBlur)!)
    }
  }

  inputKey(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.handleSearchBlur()
    }
  }

  shouldRenderSubmissionOption(): boolean {
    return this.showSubmissionOptionIfInvalidSearchTerms || !this.invalidSearchTerms()
  }

  static tagFn(name: string) {
    return html`<span class="lh-condensed px-1 rounded-1 border color-border-success">${name}</span>`
  }
  alphaTag = BaseFilterElement.tagFn('Alpha')
  betaTag = BaseFilterElement.tagFn('Beta')
}
